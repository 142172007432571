export function combineUrl(
	host: string,
	path: string,
	searchParams: URLSearchParams
) {
	const url = new URL(path, host)
	url.search = searchParams.toString()

	//TODO change return tipe string to URL
	return url.href
}
