import {
	CookiesActionName,
	CookiesBannerLevel,
	CookiesBannerName,
	type CookiesBannerStep,
	type CookiesSettingsActions,
	type PreferencesCookiesCategories,
} from 'cookies/types/index'

import { pushToDatalayer } from '../../analytics/utils/pushToDatalayer/pushToDatalayer'

export const SendAnalyticsConfirmation = ({
	categories,
	section,
	step,
}: {
	categories: PreferencesCookiesCategories
	section: CookiesSettingsActions
	step: CookiesBannerStep
}) => {
	const payload = {
		event: CookiesBannerName.name,
		event_name: CookiesBannerName.confirmation,
		[CookiesBannerName.confirmation]: {
			...categories,
			location: CookiesBannerLevel[step],
			section: CookiesActionName[section],
		},
	}

	pushToDatalayer(payload)
}
