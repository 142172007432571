import { ButtonContentLink } from '@fukku/button/ContentLink'
import { ButtonPrimary } from '@fukku/button/Primary'
import { ButtonSecondary } from '@fukku/button/Secondary'
import { useCookiesCompliance } from 'cookies/hooks/useCookiesCompliance'
import { useCookiesComplianceLevel } from 'cookies/hooks/useCookiesComplianceLevel'
import { useCookiesContext } from 'cookies/hooks/useCookiesContext'
import { ButtonStyle, ButtonType } from 'cookies/types'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { cx } from 'utils/cx'

import styles from './CookiesFooter.module.scss'

const testId = 'cookies.button.acceptAll'

export function CookiesFooter() {
	const { t } = useLabels()
	const { rejectAllCookies, allowAllCookies } = useCookiesCompliance()
	const { goToFirstLevel } = useCookiesComplianceLevel()
	const { buttonsConfiguration } = useCookiesContext()
	const { settings, rejectAll, acceptAll } = buttonsConfiguration

	const getCorrectButtonComponent = (button: {
		type?: ButtonType
		style?: ButtonStyle
	}) => {
		if (button.type === ButtonType.Button) {
			if (button.style === ButtonStyle.Primary) {
				return ButtonPrimary
			}
			return ButtonSecondary
		}

		return ButtonContentLink
	}

	const ConfigButtonComponent = getCorrectButtonComponent(settings)
	const RejectButtonComponent = getCorrectButtonComponent(rejectAll)
	const AcceptButtonComponent = getCorrectButtonComponent(acceptAll)

	return (
		<div
			className={cx(
				styles.cookiesFooter,
				settings.type === ButtonType.Button && styles.centerCookiesFooter
			)}
		>
			<ConfigButtonComponent
				className={styles.button}
				onClick={goToFirstLevel}
				data-testid='cookies.button.settings'
			>
				{t('cookies.banner.configuration.button')}
			</ConfigButtonComponent>
			{rejectAll.firstLevel && rejectAll.type === ButtonType.Button && (
				<RejectButtonComponent
					className={styles.button}
					onClick={rejectAllCookies}
					data-testid='cookies.button.rejectAll'
				>
					{t('cookies.configuration.rejectAll.button')}
				</RejectButtonComponent>
			)}
			<AcceptButtonComponent
				className={styles.button}
				id={testId}
				onClick={allowAllCookies}
				data-testid={testId}
			>
				{t('cookies.banner.accepAll.button')}
			</AcceptButtonComponent>
		</div>
	)
}
