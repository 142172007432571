import type { OptimizelyDecision } from '@optimizely/optimizely-sdk'

export enum CookiesSettingsActions {
	allowAll = 'allowAll',
	rejectAll = 'rejectAll',
	save = 'save',
}

export enum COOKIES_PREFERENCES {
	privacy = 'privacy',
	necessary = 'necessary',
	functional = 'functional',
	performance = 'performance',
	targeting = 'targeting',
	social = 'social',
}

export enum CookiesBannerName {
	name = 'GA4-cookies',
	confirmation = 'cookies_confirmation',
}

export enum CookiesActionName {
	allowAll = 'accept_all',
	rejectAll = 'reject_all',
	save = 'save_configuration',
}

export enum CookiesBannerStep {
	initialStep = 0,
	firstStep = 1,
	secondStep = 2,
}

export enum CookiesBannerLevel {
	FIRST_LEVEL = 0,
	SECOND_LEVEL = 1,
	THIRD_LEVEL = 2,
}

export enum PreferencesCookiesCategoriesValue {
	true = 'GRANTED',
	false = 'DENIED',
	disabled = 'NO_CHOICE',
}
export interface PreferencesCookiesCategories {
	necessary: PreferencesCookiesCategoriesValue
	functional: PreferencesCookiesCategoriesValue
	performance: PreferencesCookiesCategoriesValue
	targeting: PreferencesCookiesCategoriesValue
	social: PreferencesCookiesCategoriesValue
}

export enum ButtonType {
	Button = 'button',
	Link = 'link',
}

export enum ButtonStyle {
	Primary = 'primary',
	Secondary = 'secondary',
}

export interface ButtonConfigurationType {
	settings: {
		type: ButtonType
		style?: ButtonStyle
	}
	rejectAll: {
		firstLevel: boolean
		type?: ButtonType
		style?: ButtonStyle
	}
	acceptAll: {
		type: ButtonType
		style?: ButtonStyle
	}
}

// OPTIMIZELY DATA
export interface CookiesBannerVariables {
	variables: {
		categories: {
			necessary: {
				blocked: boolean
				status: boolean
			}
			functional: {
				blocked: boolean
				status: boolean
			}
			performance: {
				blocked: boolean
				status: boolean
			}
			targeting: {
				blocked: boolean
				status: boolean
			}
			social: {
				blocked: boolean
				status: boolean
			}
		}
		buttonsConfiguration: ButtonConfigurationType
		blockedPageTypes: { pageTypes: string[] }
	}
	enabled: OptimizelyDecision['enabled']
}
