'use client'

import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useSession } from 'session/src/hooks/useSession'

import { useCallback } from 'react'
import { sendBeaconServerEvent } from '../../events/beacon/sendBeaconServerEvent'
import {
	type AddToCartPayload,
	type MapAddToCartToBeaconProps,
	mapAddToCartToBeacon,
} from '../../events/eec/addToCart/addToCart'
import {
	type AddToWishlistPayload,
	type MapAddToWishlistToBeaconProps,
	mapAddToWishlistToBeacon,
} from '../../events/eec/addToWishlist/addToWishlist'
import {
	type BeginCheckoutPayload,
	type MapBeginCheckoutToBeaconProps,
	mapBeginCheckoutToBeacon,
} from '../../events/eec/beginCheckout/beginCheckout'
import {
	type MapRemoveFromCartToBeaconProps,
	type RemoveFromCartPayload,
	mapRemoveFromCartToBeacon,
} from '../../events/eec/removeFromCart/removeFromCart'
import {
	type RemoveFromWishlistPayload,
	mapRemoveFromWishlistToBeacon,
} from '../../events/eec/removeFromWishlist/removeFromWishlist'
import {
	type MapSelectItemToBeaconProps,
	type SelectItemPayload,
	mapSelectItemToBeacon,
} from '../../events/eec/selectItem/selectItem'
import {
	type MapViewCartToBeaconProps,
	type ViewCartPayload,
	mapViewCartToBeacon,
} from '../../events/eec/viewCart/viewCart'
import {
	type MapViewItemToBeaconProps,
	mapViewItemToBeacon,
} from '../../events/eec/viewItem/viewItem'
import {
	type MapViewItemListToBeaconProps,
	type ViewItemListPayload,
	mapViewItemListToBeacon,
} from '../../events/eec/viewItemList/viewItemList'
import type { ViewItemPayload } from '../../types/EEC'

interface UseEECEvents {
	sendAddToCart: (params: AddToCartPayload) => Promise<void>
	sendBeginCheckout: (params: BeginCheckoutPayload) => void
	sendRemoveFromCart: (params: RemoveFromCartPayload) => Promise<void>
	sendSelectItem: (params: SelectItemPayload) => Promise<void>
	sendViewCart: (params: ViewCartPayload) => void
	sendViewItem: (params: ViewItemPayload) => Promise<void>
	sendViewItemList: (params: ViewItemListPayload) => Promise<void>
	sendAddToWishlist: (params: AddToWishlistPayload) => Promise<void>
	sendRemoveFromWishlist: (params: RemoveFromWishlistPayload) => Promise<void>
}

export function useEECEvents(): UseEECEvents {
	const session = useSession()
	const masterData = useMasterData()

	const sendAddToCart = async ({
		item,
		mngItems,
		location,
		listId,
	}: AddToCartPayload): Promise<void> => {
		const payload: MapAddToCartToBeaconProps = {
			session,
			masterData,
			payload: {
				item,
				mngItems,
				location,
				listId,
			},
		}

		sendBeaconServerEvent(session, await mapAddToCartToBeacon(payload))
	}

	const sendViewCart = ({
		bagItemNumber,
		location,
		items,
	}: ViewCartPayload): void => {
		const payload: MapViewCartToBeaconProps = {
			session,
			masterData,
			payload: {
				items,
				location,
				bagItemNumber,
			},
		}

		sendBeaconServerEvent(session, mapViewCartToBeacon(payload))
	}

	const sendRemoveFromCart = async ({
		location,
		item,
		mngItems,
	}: RemoveFromCartPayload): Promise<void> => {
		const payload: MapRemoveFromCartToBeaconProps = {
			session,
			masterData,
			payload: {
				item,
				location,
				mngItems,
			},
		}

		sendBeaconServerEvent(session, await mapRemoveFromCartToBeacon(payload))
	}

	const sendBeginCheckout = ({
		location,
		items,
	}: BeginCheckoutPayload): void => {
		const payload: MapBeginCheckoutToBeaconProps = {
			session,
			masterData,
			payload: {
				items,
				location,
			},
		}

		sendBeaconServerEvent(session, mapBeginCheckoutToBeacon(payload))
	}

	const sendViewItem = async ({ item }: ViewItemPayload): Promise<void> => {
		const payload: MapViewItemToBeaconProps = {
			session,
			masterData,
			payload: {
				item,
			},
		}

		sendBeaconServerEvent(session, await mapViewItemToBeacon(payload))
	}

	const sendSelectItem = async ({
		item,
		location,
		listId,
	}: SelectItemPayload): Promise<void> => {
		const payload: MapSelectItemToBeaconProps = {
			session,
			masterData,
			payload: {
				item,
				location,
				listId,
			},
		}

		sendBeaconServerEvent(session, await mapSelectItemToBeacon(payload))
	}

	const sendViewItemList = useCallback(
		async ({ items }: ViewItemListPayload): Promise<void> => {
			const payload: MapViewItemListToBeaconProps = {
				session,
				masterData,
				payload: {
					items,
				},
			}

			sendBeaconServerEvent(session, await mapViewItemListToBeacon(payload))
		},
		[masterData, session]
	)

	const sendAddToWishlist = async ({
		item,
		location,
		listId,
	}: AddToWishlistPayload): Promise<void> => {
		const payload: MapAddToWishlistToBeaconProps = {
			session,
			masterData,
			payload: {
				item,
				location,
				listId,
			},
		}

		sendBeaconServerEvent(session, await mapAddToWishlistToBeacon(payload))
	}

	const sendRemoveFromWishlist = async ({
		item,
		location,
		listId,
	}: RemoveFromWishlistPayload): Promise<void> => {
		const payload: MapAddToWishlistToBeaconProps = {
			session,
			masterData,
			payload: {
				item,
				location,
				listId,
			},
		}

		sendBeaconServerEvent(session, await mapRemoveFromWishlistToBeacon(payload))
	}

	return {
		sendAddToCart,
		sendViewCart,
		sendRemoveFromCart,
		sendBeginCheckout,
		sendViewItem,
		sendSelectItem,
		sendViewItemList,
		sendAddToWishlist,
		sendRemoveFromWishlist,
	}
}
