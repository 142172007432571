import { cx } from 'utils/cx'

import fukkuStyle from '@fukku/styles/classes.module.scss'
import styles from './Loader.module.scss'

export interface LoaderProps {
	readonly inverse?: boolean
	readonly loading?: boolean
	readonly className?: string
	readonly accessibilityText: {
		loading: string
		loaded: string
	}
}

export function Loader({
	inverse = false,
	loading = false,
	className,
	accessibilityText,
}: LoaderProps) {
	const computedClassName = cx(styles.square, inverse && styles.inverse)
	return (
		<div aria-live='polite' className={className}>
			{loading ? (
				<div className={cx(styles.loader, inverse && styles.inverse)}>
					<p className={fukkuStyle.srOnly}>{accessibilityText.loading}</p>
					<div className={computedClassName} />
					<div className={cx(computedClassName, styles.square2)} />
					<div className={cx(computedClassName, styles.square3)} />
				</div>
			) : (
				<p className={fukkuStyle.srOnly}>{accessibilityText.loaded}</p>
			)}
		</div>
	)
}
