import { cx } from 'utils/cx'

import type { ButtonProps } from '../Button.types'
import { BUTTON_VARIANT } from '../Button.types'
import { ButtonBase } from '../ButtonBase'
import { ButtonContentWrapper } from '../ButtonContentWrapper/ButtonContentWrapper'

import styles from './ButtonPrimary.module.scss'

export function ButtonPrimary(props: Readonly<ButtonProps>): React.ReactNode {
	const {
		isLoading = false,
		variant = BUTTON_VARIANT.DEFAULT,
		className,
		disabled,
		children,
		fullWidth,
		accessibilityText,
		...rest
	} = props

	const disabledButton = disabled || isLoading

	return (
		<ButtonBase
			{...rest}
			className={cx(
				className,
				styles[variant],
				disabled && !isLoading && styles.disabled,
				fullWidth && styles.fullWidth
			)}
			disabled={disabledButton}
		>
			<ButtonContentWrapper
				isLoading={isLoading}
				variant={BUTTON_VARIANT.DEFAULT}
				accessibilityText={accessibilityText}
			>
				{children}
			</ButtonContentWrapper>
		</ButtonBase>
	)
}
