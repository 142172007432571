import type { Session } from 'session/src/types/session'
import type { MasterData } from 'types/masterData'

import type { CommonParams } from '../../../types/CommonParams'
import {
	type BeaconEvent,
	EECEventType,
	type MngItem,
} from '../../../types/EEC'
import { getCommonParams } from '../../../utils/getCommonParams/getCommonParams'
import {
	type EECItem,
	type Item,
	getEECItemsAsync,
} from '../../../utils/getEECItems/getEECItems'

export interface MapAddToCartToBeaconProps {
	masterData: MasterData
	session: Session
	payload: AddToCartPayload
}

export interface AddToCartPayload {
	item: Item
	mngItems: MngItem[]
	location: string
	listId?: string
}

export interface BeaconAddToCartEvent {
	name: EECEventType.ADD_TO_CART
	params: CommonParams & {
		items: EECItem[]
		mng_items: string
	}
}

export const mapAddToCartToBeacon = async ({
	masterData,
	session,
	payload,
}: MapAddToCartToBeaconProps): Promise<BeaconEvent> => ({
	name: EECEventType.ADD_TO_CART,
	params: {
		...getCommonParams({
			masterData,
			session,
			location: payload.location,
			listId: payload.listId,
		}),
		items: await getEECItemsAsync(masterData, [
			{ ...payload.item, listId: payload.listId },
		]),
		mng_items: JSON.stringify(payload.mngItems),
	},
})
